/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { improvementDirections } from 'embercom/components/reporting/element/summary-comparison';
import {
  botInboxAndOfficeHoursField,
  timeTimeToCloseField,
} from 'embercom/lib/reporting/office-hours-field';
import Admin from 'embercom/models/admin';
import { FILTER_PROPERTY_PLACEHOLDER } from 'embercom/components/reporting/flexible/chart';
import moment from 'moment-timezone';
import dateAndTimeFormats from 'embercom/lib/date-and-time-formats';
import ViewConfig, { GROUPINGS } from 'embercom/lib/reporting/flexible/view-config';
import {
  AVAILABLE_CHANNEL_MAPPINGS,
  STANDALONE_AVAILABLE_CHANNEL_MAPPINGS,
  RANGE,
  MEDIAN,
  MEAN,
  MAX,
  MIN,
  FIRST_USER_CONVERSATION_PART_CREATED_AT,
  CHANNEL,
  CONVERSATION_PART_REPLIES_FILTERS,
} from 'embercom/lib/reporting/flexible/constants';
import { units } from 'embercom/lib/reporting/flexible/formatters';
import { reverseFilterMappings } from 'embercom/lib/reporting/flexible/reverse-filter-mappings';

export default class ResponsivenessFlexibleController extends Controller {
  @service appService;
  @service intercomEventService;
  @service reportingService;
  @service store;
  @service reportSubscriptionService;
  @service intl;
  @service notificationsService;
  @tracked range;
  scopingTagIds = null;
  tag = null;
  teammateId = null;
  teamOrTeammate = null;
  subscriptionId = null;
  source = null;
  cameFrom = null;

  queryParams = ['subscriptionId', 'source', 'cameFrom'];

  constructor() {
    super(...arguments);
  }

  get app() {
    return this.appService.app;
  }

  get customAttributeIds() {
    return this.conversationAttributeDescriptors.map((descriptor) => descriptor.id);
  }

  get botInboxTimeExcluded() {
    return this.botInboxTime?.[0] === 'excluded';
  }

  get dashboardName() {
    return this.intl.t('reporting.responsiveness.title');
  }

  get reverseFilterMappings() {
    return reverseFilterMappings(this);
  }

  @action
  updateRange(range) {
    let excludeBotInboxStartDate = null;
    if (this.botInboxTimeExcluded) {
      excludeBotInboxStartDate = this.settings.excludeBotInboxStartDate;
    }
    this.reportingService.updateRange(range, excludeBotInboxStartDate);

    let params = {
      action: 'filtered_by_date',
      object: this.reportName,
      date_filter: this.range.selectedRange,
      start_date: range.startMoment.format('YYYY/MM/DD'),
      end_date: range.endMoment.format('YYYY/MM/DD'),
    };
    let teamOrTeammate = this.teamOrTeammate;
    if (teamOrTeammate) {
      params.models = [teamOrTeammate];
    }
    this.intercomEventService.trackAnalyticsEvent(params);
    this.clearReportSubscription();
  }

  @action
  changeTeamOrTeammate(teamOrTeammateId) {
    let isTeam =
      teamOrTeammateId && Admin.peekAndMaybeLoad(this.store, teamOrTeammateId)?.get('isTeam');

    if (isTeam) {
      this.setProperties({
        teammateId: [],
        teamsParticipated: teamOrTeammateId,
      });
    } else {
      this.setProperties({
        teammateId: teamOrTeammateId,
        teamsParticipated: [],
      });
    }
    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered_by_teammate',
      object: this.reportName,
    });
  }

  @action
  changeTag(tag) {
    let scopingTagIds = null;
    if (tag !== null) {
      scopingTagIds = [tag.get('id')];
    }
    this.setProperties({ scopingTagIds, tag });
  }

  @action
  toggleBotInboxTime(value) {
    //All filters are now arrays. The pre-filter bar implementation of the bot filter uses single values
    this.set('botInboxTime', [value]);

    if (
      this.botInboxTimeExcluded &&
      this.range.startMoment.isBefore(this.settings.excludeBotInboxStartDate)
    ) {
      this.notificationsService.notifyWarning(
        `Data to exclude bots is available from ${moment(
          this.settings.excludeBotInboxStartDate,
        ).format(dateAndTimeFormats.date)}, when your bot inbox was activated.`,
        5000,
      );
    }
  }

  @action
  trackPageView() {
    let models = [];
    if (this.teammate) {
      models = [this.teammate];
    }
    if (this.teamOrTeammate) {
      models = [...models, this.teamOrTeammate];
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: this.reportName,
      context: this.reportContext,
      start_date: this.range.startMoment.format('YYYY/MM/DD'),
      end_date: this.range.endMoment.format('YYYY/MM/DD'),
      filtered_tag: this.get('tag.name'),
      models,
      came_from: this.cameFrom,
    });
    this.trackReportSubscriptionEmailLinkClick();
  }

  trackReportSubscriptionEmailLinkClick() {
    if (this.source === 'subscriptionEmail') {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'report_subscription_email_link',
      });
    }
  }

  get counterDefaultConfigs() {
    return {
      responseTimeCounter: {
        viewConfig: this.buildViewConfig({
          unit: units.minute,
          groupings: {
            defaultOption: {
              ...GROUPINGS[FIRST_USER_CONVERSATION_PART_CREATED_AT],
              isComparison: true,
            },
          },
          counter: {
            comparison_counter: {
              improvementDirection: improvementDirections.DECREASING,
            },
          },
        }),
        dataConfig: {
          series: [
            {
              name: 'comparison_counter',
              source: 'consolidated_conversation_part',
              filter: {
                type: 'and',
                filters: CONVERSATION_PART_REPLIES_FILTERS,
              },
              type: 'median',
              data: {
                property: botInboxAndOfficeHoursField(
                  'response_time',
                  this.model.settings.reportingExcludeOooHours,
                  this.botInboxTimeExcluded,
                  true,
                ),
              },
              time: {
                property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
                start: FILTER_PROPERTY_PLACEHOLDER,
                end: FILTER_PROPERTY_PLACEHOLDER,
              },
            },
          ],
        },
      },
      firstResponseTimeCounter: {
        viewConfig: this.buildViewConfig({
          unit: units.minute,
          groupings: {
            defaultOption: {
              ...GROUPINGS[FIRST_USER_CONVERSATION_PART_CREATED_AT],
              isComparison: true,
            },
          },
          counter: {
            comparison_counter: {
              improvementDirection: improvementDirections.DECREASING,
            },
          },
        }),
        dataConfig: this.firstResponseTimeCounterDataConfig,
      },
      timeToCloseCounter: {
        viewConfig: this.buildViewConfig({
          unit: units.minute,
          groupings: {
            defaultOption: {
              ...GROUPINGS[FIRST_USER_CONVERSATION_PART_CREATED_AT],
              isComparison: true,
            },
          },
          counter: {
            comparison_counter: {
              improvementDirection: improvementDirections.DECREASING,
            },
          },
        }),
        dataConfig: this.timeToCloseCounterDataConfig,
      },
    };
  }

  flexibleChartGroupings() {
    let groupings = [];
    let labelMappings = this.app.canUseStandalone
      ? STANDALONE_AVAILABLE_CHANNEL_MAPPINGS
      : AVAILABLE_CHANNEL_MAPPINGS;
    groupings.push({ ...GROUPINGS[CHANNEL], labelMappings });
    return groupings;
  }

  get responseTimeConfig() {
    return {
      viewConfig: this.buildViewConfig({
        unit: units.seconds,
        groupings: {
          defaultOption: {
            ...GROUPINGS[FIRST_USER_CONVERSATION_PART_CREATED_AT],
            isComparison: true,
          },
          rest: this.flexibleChartGroupings(),
        },
      }),
      dataConfig: {
        xAxis: {
          type: 'temporal',
          data: {
            interval: FILTER_PROPERTY_PLACEHOLDER,
            property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
          },
        },
        series: [
          {
            name: 'comparison_columnchart',
            source: 'consolidated_conversation_part',
            filter: {
              type: 'and',
              filters: CONVERSATION_PART_REPLIES_FILTERS,
            },
            type: 'median',
            data: {
              property: botInboxAndOfficeHoursField(
                'response_time',
                this.model.settings.reportingExcludeOooHours,
                this.botInboxTimeExcluded,
                true,
              ),
            },
            time: {
              property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
              start: FILTER_PROPERTY_PLACEHOLDER,
              end: FILTER_PROPERTY_PLACEHOLDER,
            },
          },
        ],
      },
    };
  }

  get columnChartConfigs() {
    return {
      firstResponseTime: {
        viewConfig: this.buildViewConfig({
          unit: units.seconds,
          groupings: {
            defaultOption: GROUPINGS[FIRST_USER_CONVERSATION_PART_CREATED_AT],
            rest: this.flexibleChartGroupings(),
          },
        }),
        dataConfig: this.firstResponseTimeChartDataConfig,
      },
      firstResponseTimeBreakdown: {
        viewConfig: this.buildViewConfig({
          unit: units.percent,
          aggregations: { defaultOption: RANGE },
          groupings: {
            defaultOption: GROUPINGS[FIRST_USER_CONVERSATION_PART_CREATED_AT],
          },
          tableChart: {
            columns: [
              {
                label: this.intl.t(
                  'reporting.responsiveness.first-response-time-breakdown.first-response',
                ),
                valuePath: 'bucketRange',
              },
              {
                label: this.intl.t(
                  'reporting.responsiveness.first-response-time-breakdown.percentage-replies',
                ),
                valuePath: 'percentage',
              },
            ],
          },
        }),
        dataConfig: this.firstResponseTimeBreakdownDataConfig,
      },
      timeToClose: {
        viewConfig: this.buildViewConfig({
          unit: units.seconds,
          groupings: {
            defaultOption: GROUPINGS[FIRST_USER_CONVERSATION_PART_CREATED_AT],
            rest: this.flexibleChartGroupings(),
          },
        }),
        dataConfig: this.timeToCloseDataConfig,
      },
      timeToCloseBreakdown: {
        viewConfig: this.buildViewConfig({
          unit: units.percent,
          aggregations: { defaultOption: RANGE },
          groupings: {
            defaultOption: GROUPINGS[FIRST_USER_CONVERSATION_PART_CREATED_AT],
          },
          tableChart: {
            columns: [
              {
                label: this.intl.t(
                  'reporting.responsiveness.time-to-close-breakdown.time-to-close',
                ),
                valuePath: 'bucketRange',
              },
              {
                label: this.intl.t(
                  'reporting.responsiveness.time-to-close-breakdown.percentage-conversations',
                ),
                valuePath: 'percentage',
              },
            ],
          },
        }),
        dataConfig: this.timeToCloseBreakdownDataConfig,
      },
    };
  }

  buildViewConfig({
    unit,
    displayUnit = '',
    aggregations = { defaultOption: MEDIAN, rest: [MIN, MAX, MEAN] },
    groupings,
    tableChart = {},
    counter = {},
  }) {
    let viewConfig = new ViewConfig();

    viewConfig.aggregations = aggregations;
    if (Object.keys(groupings).length) {
      viewConfig.groupings = groupings;
    }
    viewConfig.formatUnit = { unit, displayUnit };
    viewConfig.counter = counter;
    viewConfig.tableChart = tableChart;

    return viewConfig;
  }

  clearReportSubscription() {
    this.reportSubscriptionService.resetSubscription();
    this.set('subscriptionId', null);
    this.set('source', null);
  }

  @action
  filterBarSelectionChanged(filterType, filterValues, operator) {
    this.clearReportSubscription();
    //backwards compatible with existing teammate filters before the filter bar was introduced
    if (filterType === 'teammatesParticipated') {
      this.set('teammateId', filterValues);
    } else if (this.customAttributeIds.includes(filterType)) {
      this.customAttributes.updateValues(filterType, filterValues, operator);
      this.set('customAttributes', this.customAttributes.clone());
    } else {
      this.set(filterType, filterValues);
    }
  }

  @action
  filterBarCleared() {
    this.clearReportSubscription();
  }

  // --------- conversation_closed migration -------------
  get timeToCloseBreakdownDataConfig() {
    if (this.appService.app.canQueryPowerfulIndices) {
      return {
        series: [
          {
            name: 'time_to_close_tablechart',
            source: 'conversation',
            type: 'range',
            filter: {
              type: 'category',
              data: {
                property: 'has_human_authored_closer',
                values: [true],
              },
            },
            data: {
              property: botInboxAndOfficeHoursField(
                timeTimeToCloseField(this.botInboxTimeExcluded),
                this.model.settings.reportingExcludeOooHours,
                this.botInboxTimeExcluded,
                this.appService.app.canQueryPowerfulIndices,
              ),
              ranges: [
                { to: 5 * 60 },
                { from: 5 * 60, to: 15 * 60 },
                { from: 15 * 60, to: 30 * 60 },
                { from: 30 * 60, to: 60 * 60 },
                { from: 60 * 60, to: 3 * 60 * 60 },
                { from: 3 * 60 * 60, to: 8 * 60 * 60 },
                { from: 8 * 60 * 60 },
              ],
            },
            time: {
              property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
              start: FILTER_PROPERTY_PLACEHOLDER,
              end: FILTER_PROPERTY_PLACEHOLDER,
            },
          },
        ],
      };
    } else {
      return {
        series: [
          {
            name: 'time_to_close_tablechart',
            source: 'conversation_closed',
            type: 'range',
            data: {
              property: botInboxAndOfficeHoursField(
                timeTimeToCloseField(this.botInboxTimeExcluded),
                this.model.settings.reportingExcludeOooHours,
                this.botInboxTimeExcluded,
              ),
              ranges: [
                { to: 5 * 60 },
                { from: 5 * 60, to: 15 * 60 },
                { from: 15 * 60, to: 30 * 60 },
                { from: 30 * 60, to: 60 * 60 },
                { from: 60 * 60, to: 3 * 60 * 60 },
                { from: 3 * 60 * 60, to: 8 * 60 * 60 },
                { from: 8 * 60 * 60 },
              ],
            },
            time: {
              property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
              start: FILTER_PROPERTY_PLACEHOLDER,
              end: FILTER_PROPERTY_PLACEHOLDER,
            },
          },
        ],
      };
    }
  }

  get timeToCloseCounterDataConfig() {
    if (this.appService.app.canQueryPowerfulIndices) {
      return {
        series: [
          {
            name: 'comparison_counter',
            source: 'conversation',
            type: 'median',
            filter: {
              type: 'category',
              data: {
                property: 'has_human_authored_closer',
                values: [true],
              },
            },
            data: {
              property: botInboxAndOfficeHoursField(
                timeTimeToCloseField(this.botInboxTimeExcluded),
                this.model.settings.reportingExcludeOooHours,
                this.botInboxTimeExcluded,
                this.appService.app.canQueryPowerfulIndices,
              ),
            },
            time: {
              property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
              start: FILTER_PROPERTY_PLACEHOLDER,
              end: FILTER_PROPERTY_PLACEHOLDER,
            },
          },
        ],
      };
    } else {
      return {
        series: [
          {
            name: 'comparison_counter',
            source: 'conversation_closed',
            type: 'median',
            data: {
              property: botInboxAndOfficeHoursField(
                timeTimeToCloseField(this.botInboxTimeExcluded),
                this.model.settings.reportingExcludeOooHours,
                this.botInboxTimeExcluded,
              ),
            },
            time: {
              property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
              start: FILTER_PROPERTY_PLACEHOLDER,
              end: FILTER_PROPERTY_PLACEHOLDER,
            },
          },
        ],
      };
    }
  }

  get timeToCloseDataConfig() {
    if (this.appService.app.canQueryPowerfulIndices) {
      return {
        xAxis: {
          type: 'temporal',
          data: {
            interval: FILTER_PROPERTY_PLACEHOLDER,
            property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
          },
        },
        series: [
          {
            name: 'time_to_close_columnchart',
            source: 'conversation',
            type: 'median',
            filter: {
              type: 'category',
              data: {
                property: 'has_human_authored_closer',
                values: [true],
              },
            },
            data: {
              property: botInboxAndOfficeHoursField(
                timeTimeToCloseField(this.botInboxTimeExcluded),
                this.model.settings.reportingExcludeOooHours,
                this.botInboxTimeExcluded,
                this.appService.app.canQueryPowerfulIndices,
              ),
            },
            time: {
              property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
              start: FILTER_PROPERTY_PLACEHOLDER,
              end: FILTER_PROPERTY_PLACEHOLDER,
            },
          },
        ],
      };
    } else {
      return {
        xAxis: {
          type: 'temporal',
          data: {
            interval: FILTER_PROPERTY_PLACEHOLDER,
            property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
          },
        },
        series: [
          {
            name: 'time_to_close_columnchart',
            source: 'conversation_closed',
            type: 'median',
            data: {
              property: botInboxAndOfficeHoursField(
                timeTimeToCloseField(this.botInboxTimeExcluded),
                this.model.settings.reportingExcludeOooHours,
                this.botInboxTimeExcluded,
              ),
            },
            time: {
              property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
              start: FILTER_PROPERTY_PLACEHOLDER,
              end: FILTER_PROPERTY_PLACEHOLDER,
            },
          },
        ],
      };
    }
  }

  // --------- initial_admin_reply migration -------------

  get firstResponseTimeChartDataConfig() {
    if (this.appService.app.canQueryPowerfulIndices) {
      return {
        xAxis: {
          type: 'temporal',
          data: {
            interval: FILTER_PROPERTY_PLACEHOLDER,
            property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
          },
        },
        series: [
          {
            name: 'first_response_time_columnchart',
            source: 'conversation',
            type: 'median',
            data: {
              property: botInboxAndOfficeHoursField(
                'first_response_time',
                this.model.settings.reportingExcludeOooHours,
                this.botInboxTimeExcluded,
                this.appService.app.canQueryPowerfulIndices,
              ),
            },
            time: {
              property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
              start: FILTER_PROPERTY_PLACEHOLDER,
              end: FILTER_PROPERTY_PLACEHOLDER,
            },
          },
        ],
      };
    } else {
      return {
        xAxis: {
          type: 'temporal',
          data: {
            interval: FILTER_PROPERTY_PLACEHOLDER,
            property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
          },
        },
        series: [
          {
            name: 'first_response_time_columnchart',
            source: 'initial_admin_reply',
            type: 'median',
            data: {
              property: botInboxAndOfficeHoursField(
                'response_time',
                this.model.settings.reportingExcludeOooHours,
                this.botInboxTimeExcluded,
              ),
            },
            time: {
              property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
              start: FILTER_PROPERTY_PLACEHOLDER,
              end: FILTER_PROPERTY_PLACEHOLDER,
            },
          },
        ],
      };
    }
  }

  get firstResponseTimeBreakdownDataConfig() {
    if (this.appService.app.canQueryPowerfulIndices) {
      return {
        series: [
          {
            name: 'first_response_time_tablechart',
            source: 'conversation',
            type: 'range',
            data: {
              property: botInboxAndOfficeHoursField(
                'first_response_time',
                this.model.settings.reportingExcludeOooHours,
                this.botInboxTimeExcluded,
                this.appService.app.canQueryPowerfulIndices,
              ),
              ranges: [
                { to: 30 },
                { from: 30, to: 2 * 60 },
                { from: 2 * 60, to: 5 * 60 },
                { from: 5 * 60, to: 10 * 60 },
                { from: 10 * 60, to: 30 * 60 },
                { from: 30 * 60, to: 60 * 60 },
                { from: 60 * 60 },
              ],
            },
            time: {
              property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
              start: FILTER_PROPERTY_PLACEHOLDER,
              end: FILTER_PROPERTY_PLACEHOLDER,
            },
          },
        ],
      };
    } else {
      return {
        series: [
          {
            name: 'first_response_time_tablechart',
            source: 'initial_admin_reply',
            type: 'range',
            data: {
              property: botInboxAndOfficeHoursField(
                'response_time',
                this.model.settings.reportingExcludeOooHours,
                this.botInboxTimeExcluded,
              ),
              ranges: [
                { to: 30 },
                { from: 30, to: 2 * 60 },
                { from: 2 * 60, to: 5 * 60 },
                { from: 5 * 60, to: 10 * 60 },
                { from: 10 * 60, to: 30 * 60 },
                { from: 30 * 60, to: 60 * 60 },
                { from: 60 * 60 },
              ],
            },
            time: {
              property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
              start: FILTER_PROPERTY_PLACEHOLDER,
              end: FILTER_PROPERTY_PLACEHOLDER,
            },
          },
        ],
      };
    }
  }

  get firstResponseTimeCounterDataConfig() {
    if (this.appService.app.canQueryPowerfulIndices) {
      return {
        series: [
          {
            name: 'comparison_counter',
            source: 'conversation',
            type: 'median',
            data: {
              property: botInboxAndOfficeHoursField(
                'first_response_time',
                this.model.settings.reportingExcludeOooHours,
                this.botInboxTimeExcluded,
                this.appService.app.canQueryPowerfulIndices,
              ),
            },
            time: {
              property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
              start: FILTER_PROPERTY_PLACEHOLDER,
              end: FILTER_PROPERTY_PLACEHOLDER,
            },
          },
        ],
      };
    } else {
      return {
        series: [
          {
            name: 'comparison_counter',
            source: 'initial_admin_reply',
            type: 'median',
            data: {
              property: botInboxAndOfficeHoursField(
                'response_time',
                this.model.settings.reportingExcludeOooHours,
                this.botInboxTimeExcluded,
              ),
            },
            time: {
              property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
              start: FILTER_PROPERTY_PLACEHOLDER,
              end: FILTER_PROPERTY_PLACEHOLDER,
            },
          },
        ],
      };
    }
  }
}
